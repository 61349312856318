<template>
  <div>
    <validation-observer
      ref="routeInfoForm"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h4 class="mb-0">
            {{$t('learning_path.define_lp')}}
          </h4>
          <small class="text-muted">
            {{$t('learning_path.define_lp_description')}}
          </small>
        </b-col>
        <b-col md="12" class="mb-2">
          <h5>{{$t('learning_path.path_icon_info')}}</h5>
          <b-avatar
            rounded
            variant="light"
            size="70px"
            class="mr-2 mb-1 pointer"
            button
            @click="showAvatarOptions = !showAvatarOptions"
            :src="require('@/assets/images/learning_paths/' + ($store.getters['learningPaths/getRoute'].img || '1') + '.png')"
          />
          <b-button
            :variant="!showAvatarOptions?'primary':'secondary'"
            @click="showAvatarOptions = !showAvatarOptions"
          >
            {{ showAvatarOptions ? $t('table.hide') : $t('table.change_icon')}}
          </b-button>
          <b-row v-if="showAvatarOptions">
            <b-col>
          
              <img-selector 
                :avatar_id="$store.getters['learningPaths/getRoute'].img"
                @toggle-edit-avatar="showAvatarOptions = false"
              />
            
            </b-col>
          </b-row>
        </b-col>
      </b-row>
        
      <b-row>
        <b-col md="6" class="order-sm-1">
          <h5>{{$t('learning_path.route_title')}}</h5>
          <b-form-group 
            :label="$t('learning_path.path_name_info_with_lang')"
            label-for="i-route_title"
          >
            <small v-if="errors.title" class="text-danger">{{ $t('learning_path.'+errors.title)  }}</small>
            <small v-if="$store.getters['learningPaths/getRoute'].title[chosen_lang].length >= 50" class="text-danger">{{ $t('learning_path.max_length')  }}</small>
            <b-input-group class="mb-1">
              <!-- PREPEND LANG -->
              <b-input-group-prepend>
                <b-dropdown
                  :text="chosen_lang.toUpperCase()"
                  variant="outline-primary"
                >
                  <b-dropdown-item 
                    v-for="lang in langs" 
                    :key="lang.iso_code"
                    @click="chosen_lang = lang.iso_code"
                  >{{lang.iso_code.toUpperCase()}}</b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <!-- INPUT TITLE -->
              <b-form-input 
                @keydown.enter.prevent
                id="i-route_title"
                :value="$store.getters['learningPaths/getRoute'].title[chosen_lang]"
                @input="$store.commit('learningPaths/setRouteTitle', [$event, chosen_lang])"
                maxlength="50"
              />
            </b-input-group>
          </b-form-group>

        </b-col>
        <b-col md="6" class="order-sm-2">
          <h5>{{$t('learning_path.description')}}</h5>

          <b-form-group
            :label="$t('learning_path.path_description_info_with_lang')"
            label-for="i-description"
          >
            <!-- <template v-if="$store.getters['learningPaths/getRoute'].languages.length == 0">
              <b-form-textarea
                id="i-description"
                disabled
                rows="1"
                :placeholder="$t('learning_path.path_lang_info')"
              />
            </template>
            <template v-else> -->
              <b-input-group class="mb-1">
                <!-- PREPEND LANG -->
                <b-input-group-prepend>
                  <b-dropdown
                    :text="chosen_lang.toUpperCase()"
                    variant="outline-primary"
                  >
                    <b-dropdown-item 
                      v-for="lang in langs" 
                      :key="lang.iso_code"
                      @click="chosen_lang = lang.iso_code"
                    >{{lang.iso_code.toUpperCase()}}</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-prepend>
                <b-form-textarea
                  id="i-description"
                  :value="$store.getters['learningPaths/getRoute'].description[chosen_lang]"
                  rows="3"
                  @input="$store.commit('learningPaths/setRouteDescription', [$event, chosen_lang])"

                />
              </b-input-group>
            <!-- </template> -->

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" class="order-sm-1">
          <h5>{{$t('learning_path.language')}}</h5>
          <b-form-group
            :label="$t('learning_path.path_lang_info')"
            label-for="i-languages"
          >
            <small v-if="errors.langs" class="text-danger">{{ $t('learning_path.'+errors.langs)  }}</small>
            <b-form-checkbox
              key="select-all"
              v-model="all_langs_selected"
              class="mt-2"
              @change="selectAllLanguages">
                {{ $t('learning_path.all_langs') }}
            </b-form-checkbox>
            <hr>
            <b-form-checkbox-group
              id="i-languages"
              v-model="route_languages"
              name="i-languages"
              class="demo-inline-spacing"
            >
              <b-form-checkbox v-for="option in langs"
              :key="option.id"
              :value="option">
                {{ $t('lang.'+option.name) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="order-sm-2">
          <h5 class="mt-3">{{$t('learning_path.sequential')}}</h5>
          <b-form-group
            class="mb-3"
            :label="$t('learning_path.sequential_desc')"
            label-for="i-sequential"
          >
            <b-form-radio-group
              id="i-sequential"
              v-model="route_mode"
              name="i-sequential"
              class="demo-inline-spacing"
            >
              <b-form-radio v-for="option in lp_modes"
              :key="option.id"
              :value="option">
              <span>
                {{ $t('learning_path.'+option.name) }} 
                <feather-icon 
                  icon="InfoIcon" 
                  class="ml-25 mb-25 text-primary pointer"
                  v-b-tooltip.hover.bottom="$t('learning_path.'+option.name+'_info')"
                />
              </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      
      
      <hierarchy-selector 
        class="mt-2 mb-2"
        v-if="$store.getters['user/getUserData'].id == 1081"
      />
      <p class="text-center">
        <small v-if="errors.categories" class="text-danger">{{ $t('learning_path.'+errors.categories)  }}</small>
      </p>
      
    </validation-observer>
  </div>
</template>

<script>
import ImgSelector from './ImgSelector.vue'
import HierarchySelector from './HierarchySelector.vue'

import { ValidationObserver } from 'vee-validate'
import { 
  BDropdown,
  BDropdownItem,

   BRow, BCol, BAvatar, BButton, BFormGroup, BFormCheckbox, BFormCheckboxGroup,
   BFormInput, BFormTextarea, BInputGroup, BInputGroupPrepend, BFormRadioGroup, BFormRadio,
   VBTooltip
} from 'bootstrap-vue'
export default {
  components:{
    ValidationObserver,
BDropdown,
  BDropdownItem,

    BRow, BCol, BAvatar, BButton, BFormGroup, BFormCheckbox, BFormCheckboxGroup,
    BFormInput, BFormTextarea, BInputGroup, BInputGroupPrepend, BFormRadioGroup, BFormRadio,

    ImgSelector,
    HierarchySelector
  },
   directives: {
    'b-tooltip': VBTooltip,
  },
  props:{
    errors:{
      type: Object,
      required: true
    },
    langs:{
      type: Array,
      required: true
    },
    lp_modes:{
      type: Array,
      required: true
    }
  },
  data(){
    return {
      showAvatarOptions:false,
      all_langs_selected: true,
      chosen_lang: 'es',
    }
  },
  mounted(){
    this.$store.commit('learningPaths/setRouteLanguages', this.langs)
  },
  computed: {
    route_languages:{
      get(){
        return this.$store.getters['learningPaths/getRoute'].languages
      },
      set(value){
        this.$store.commit('learningPaths/setRouteLanguages', value)
      }
    },
    route_mode:{
      get(){
        return this.$store.getters['learningPaths/getRoute'].mode
      },
      set(value){
        this.$store.commit('learningPaths/setRouteMode', value)
      }
    },
  },
  watch: {
    route_languages() {
      if(this.$store.getters['learningPaths/getRoute'].languages.length == this.langs.length){
        this.all_langs_selected = true
      } else {
        this.all_langs_selected = false
      }
    }
  },
  methods:{
    selectAllLanguages(){
      if(this.all_langs_selected){
        this.$store.commit('learningPaths/setRouteLanguages', this.langs)
      } else {
        this.$store.commit('learningPaths/setRouteLanguages', [])
      }
    },
  }
}
</script>

<style>

</style>