<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mt-0 mb-2"
      >
        <h4 class="mb-0">
          {{$t('learning_path.confirm_data')}}
        </h4>
        
        <small class="text-muted">{{$t('learning_path.confirm_data_description')}}</small>
      </b-col>
      <b-col md="8">
        <b-card>
          <h5 class="mb-2">
            {{$t('learning_path.activities')}}
          </h5>
          <selected-contents-list 
            :route_contents="$store.getters['learningPaths/getRoute'].contents"
            @toggle-content-in-list="toggleContentInList"
          />
          
        </b-card>

         <b-card v-if="$store.getters['user/getUserData'].id == 1081">
          <h5 class="mb-2">
            {{$t('learning_path.jerarquia')}}
          </h5>          
          <b-badge pill 
            @click="selectedNode(item)"
            v-for="item in $store.getters['learningPaths/getRoute'].categories"
            :key="item.id"
            class="mr-25 mb-25 pointer"
            variant="primary">
            {{ item.name }}
            <feather-icon class="ml-25" icon="XIcon" />
          </b-badge>

          
        </b-card>

      </b-col>
      
      <b-col md="4">
        <!-- SUMMARY -->
        <div class="checkout-options">
          <b-card>
            <span class="d-flex justify-content-between mb-25">
              <label class="section-label mb-1">{{$t('learning_path.summary')}}</label>
              <b-dropdown
                :text="chosen_lang.toUpperCase()"
                variant="outline-secondary"
                size="sm"
              >
                <b-dropdown-item 
                  v-for="lang in langs" 
                  :key="lang.iso_code"
                  @click="chosen_lang = lang.iso_code"
                >{{lang.iso_code.toUpperCase()}}</b-dropdown-item>
              </b-dropdown>
            </span>
            
            
            <div class="price-details">
              <div style="display: flex;">
                <b-avatar
                    rounded
                    variant="light"
                    size="50px"
                    class="mr-2"
                    :src="require('@/assets/images/learning_paths/' + ($store.getters['learningPaths/getRoute'].img || '1') + '.png')"
                  />
                <h5 class="">
                  {{resolveRouteTitle}}
                </h5>
                
              </div>
              <hr>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title">
                    {{resolveRouteDesc}}
                  </div>
                  
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <b-badge
                      class="mr-1 mb-1"
                      :key="lang.id"
                      v-for="lang in $store.getters['learningPaths/getRoute'].languages"
                      pill
                      variant="light-primary"
                    >
                      {{lang.iso_code.toUpperCase()}} 
                    </b-badge>
                  </div>
                  
                </li>

                <li class="price-detail">
                  <div class="detail-title">
                    {{$t('learning_path.sequential')}}:
                  </div>
                  <div class="detail-amt discount-amt text-primary">
                    {{$store.getters['learningPaths/getRoute'].mode?$t('learning_path.'+$store.getters['learningPaths/getRoute'].mode.name):''}}
                  </div>
                </li>
                
                <li class="price-detail">
                  <div class="detail-title">
                    {{$t('learning_path.activities')}}
                  </div>
                    <div class="detail-amt discount-amt text-primary">
                    {{$store.getters['learningPaths/getRoute'].contents.length}}
                  </div>
                </li>
              </ul>
            </div>
          </b-card>
        </div>        
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SelectedContentsList from '../components/SelectedContentsList.vue'

import { 
  BRow, BCol, BCard, BAvatar, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
export default {
  components:{
    BRow, BCol, BCard, BAvatar, BBadge, BDropdown, BDropdownItem,
    SelectedContentsList
  },
  props:{
    langs: {
      type: Array,
      required: true
    },
  },
  data(){
    return {
      chosen_lang: 'es',

    }
  },
  computed: {
    resolveRouteTitle(){
      /* let langs = this.$store.getters['learningPaths/getRoute'].languages.map(l=>l.iso_code)
      let txt = []
      
      Object.entries(this.$store.getters['learningPaths/getRoute'].title).map(x => {
        if(langs.includes(x[0])){
          if(x[1]){
            txt.push(x[1])
          }
        }
      })
      return txt?txt[0]:null */
      return this.$store.getters['learningPaths/getRoute'].title[this.chosen_lang]
    },
    resolveRouteDesc(){
      /* let langs = this.$store.getters['learningPaths/getRoute'].languages.map(l=>l.iso_code)
      let txt = []
      
      Object.entries(this.$store.getters['learningPaths/getRoute'].description).map(x => {
        if(langs.includes(x[0])){
          if(x[1]){
            txt.push(x[1])
          }
        }
      })

      return txt?txt[0]:null */
      return this.$store.getters['learningPaths/getRoute'].description[this.chosen_lang]
    },
  },
  methods:{
    toggleContentInList(content){
      //pasar al store
      let route_contents = [...this.$store.getters['learningPaths/getRoute'].contents]

      const index = route_contents.findIndex(object => {
        return object.id === content.id;
      });

      if(index < 0){
        route_contents.push(content)
      } else {
        route_contents.splice(index, 1)
      }
      this.$store.commit('learningPaths/setRouteContents', route_contents)
    },
    selectedNode(node){
      //mandar al store, gestionar jerarquias seleccionadas ?
      // console.log(node, "full node jerarquía seleccionada")

      let route_categories = [...this.$store.getters['learningPaths/getRoute'].categories]
      const index = route_categories.findIndex(object => {
        return object.id === node.id;
      });
      if(index < 0){
        route_categories.push(node)
      } else {
        route_categories.splice(index, 1)
      }
      this.$store.commit('learningPaths/setRouteCategories', route_categories)
    },
  }
}
</script>

<style>

</style>