<template>
  <div>
    <custom-breadcrumb
        :pageTitle="$t('learning_path.'+ heading)"
        :breadcrumb="[
          {
            to: 'learning-paths',
            text: this.$t('Learning Paths')
          },
        ]"
      ></custom-breadcrumb>
      <!-- <my-form-wizard /> -->
      <b-row v-if="loading">
        <b-col cols="12"  >
          <div class="text-center">
            <b-spinner label="Loading..." />
          </div>
        </b-col>
      </b-row>
      <form-wizard v-else
        @on-change="checkSelectedLangs"
        @on-complete="formSubmitted"
        class="steps-transparent mb-3"
        color="#87bced"
        :title="null"
        :subtitle="null"
        :finish-button-text="$t('forms.save')"
        :back-button-text="$t('form_wizard.back_button')"
        :next-button-text="$t('form_wizard.next_button')"
      >
        <!-- define learning path tab -->
        <tab-content
          :title="$t('learning_path.define_lp')"
          :before-change="validateForm"
        >
          <tab-learning-path-definition 
            :errors="errors"
            :langs="langs"
            :lp_modes="lp_modes"
          />
        </tab-content>

        <!-- select contents tab -->
        <tab-content
          :title="$t('learning_path.select_contents')"
          :before-change="validateContents"
        >
          <tab-learning-path-contents ref="contentsTab"/>
          <p class="text-center" >

          <small v-if="errors.contents" class="text-danger">{{ $t('learning_path.'+errors.contents)  }}</small>
          </p>
        </tab-content>

        <!-- Summary -->
        <tab-content
          class="ecommerce-application"
          :title="$t('learning_path.confirm_data')"
        >
          <tab-learning-path-summary
          :langs="langs" />
        </tab-content>

      </form-wizard>
  </div>
</template>

<script>
import {getCreateLearningPathInitialData, getLearningPathDetails, saveLearningPath} from '@/api/learningpaths'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import TabLearningPathDefinition from './form-tabs-components/TabLearningPathDefinition.vue'
import TabLearningPathContents from './form-tabs-components/TabLearningPathContents.vue'
import TabLearningPathSummary from './form-tabs-components/TabLearningPathSummary.vue'
// Wizard
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { 
  BSpinner, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components:{
    FormWizard, TabContent, 

    CustomBreadcrumb,
    TabLearningPathDefinition,
    TabLearningPathContents,
    TabLearningPathSummary,

    BSpinner, BRow, BCol,
  },
  data(){
    return {
      langs: [],
      lp_modes:[],
      errors:{
        langs:'',
        title:'',
        categories:'',
        contents:''
      },
      loading: true,
      saving: false

    }
  },
  mounted(){
    this.getData()
  },
  computed:{
      heading(){
        if(this.$route.name == 'learning-paths-edit'){
          return 'edit_btn'
        } else if(this.$route.name == 'learning-paths-duplicate'){
          return'save_copy'
        } 
        return 'create_heading'
      }

  },
  methods:{
    checkSelectedLangs(oldTab, newTab){
      if(oldTab == 0 && newTab == 1){
        let route = this.$store.getters['learningPaths/getRoute'] 
        if(route.languages.length == 1){
          this.$store.commit('learningPaths/setLanguageContentFilterOptions', route.languages[0].iso_code)
          this.$refs.contentsTab.searchContents()
        } else{
          this.$store.commit('learningPaths/setLanguageContentFilterOptions', null)
          this.$refs.contentsTab.searchContents()
        }
      }
    },
    async formSubmitted() {
      if(this.saving ==  true){
        return
      } else {
        this.saving = true
        let {title, description, languages, mode, img, contents, categories} = this.$store.getters['learningPaths/getRoute']
        if(contents.length>0){      
          let contents_ids = contents.map(x => x.id)
          let langs_ids = languages.map(x => x.id)
          let categories_ids = categories.map(x => x.id)
          
          //Si no completo algun idioma, se pone lo que este en base_title.
          // base_title va a ser:
          //  a. titulo en el idioma del usuario
          //  b. titulo en español
          //  c. titulo en inglés
          //  d. en el idioma que encuentre 1ero.
    
          let base_title = []
          // console.log("lang", this.$store.getters['user/getLang'])
          if(title[this.$store.getters['user/getLang']]){
            base_title.push(title[this.$store.getters['user/getLang']])
          } else if(title['es']){
            base_title.push(title['es'])
          } else if(title['en']){
            base_title.push(title['en'])
          } else {
            this.langs.forEach(l => {
              if(title[l.iso_code]){
                base_title.push(title[l.iso_code])
              }
            })
          }
    
          let base_description = []
          if(description[this.$store.getters['user/getLang']]){
            base_description.push(description[this.$store.getters['user/getLang']])
          } else if(description['es']){
            base_description.push(description['es'])
          } else if(description['en']){
            base_description.push(description['en'])
          } else {
            this.langs.forEach(l => {
              if(description[l.iso_code] != ''){
                base_description.push(description[l.iso_code])
              }
            })
          }
    
          // console.log("base_title", base_title)
          // console.log("base_description", base_description)
          let full_title = {}
          let full_description = {}
    
          this.langs.forEach(l => {
            if(title[l.iso_code] == ''){
              full_title[l.iso_code] = base_title[0]
            } else {
              full_title[l.iso_code] = title[l.iso_code]
            }
            if(description[l.iso_code] == ''){
              full_description[l.iso_code] = base_description[0] || ''
            } else {
              full_description[l.iso_code] = description[l.iso_code]
            }
          })
          
          let data = {
            'action': this.heading,
            'token': this.$store.getters['user/getUserData'].sso,
            'lp_id': this.$route.params.id ?? null,
            'user_id':  this.$store.getters['user/getUserData'].id,
            'contents': contents_ids,
            'langs': langs_ids,
            'mode': mode.id, 
            'title': full_title,
            'description': full_description,
            img,
            'categories': categories_ids //Array de ids de las categorias de los LP (hierarchy)
          }
          // console.log("DATA SAVE", data)
          await this.$http.post(saveLearningPath, data).then( response => {
            // console.log(response)
            if(response.data.status == 200){
              this.saving = false
              this.$router.push({name: 'learning-paths'})
              this.makeToast('success', this.$t('Success'),this.$t('learning_path.save_lp_success'));
            } else {
              this.saving = false
              this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
            }
          }).catch(error => {
              this.saving = false
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          })
        } else {
          this.clearErrors()
          let success = this.$store.getters['learningPaths/getRoute'].contents.length >=1
          if(!success){
            this.errors.contents = 'contents_missing'
          }
        }
      }
    },
    clearErrors(){
      this.errors = {
        langs:'',
        title:'',
        categories: '',
        contents: ''
      }
    },
    validateForm() {
      this.clearErrors()
      let success = true
      if(this.$store.getters['learningPaths/getRoute'].languages.length == 0){
        this.errors.langs = 'language_missing'
        success = false
      } else {
        let title_empty = true
        Object.values(this.$store.getters['learningPaths/getRoute'].title).map(x => {
          if(x){ 
            title_empty = false
          }
        })
        if(title_empty){
          this.errors.title = 'title_missing'
          success = false
        }
      }
      if(this.$store.getters['user/getUserData'].id == 1081){
        if(this.$store.getters['learningPaths/getRoute'].categories.length == 0){
          this.errors.categories = 'hierarchy_missing'
          success = false
        }
      }

      return new Promise((resolve, reject) => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    },
    validateContents(){
      this.clearErrors()
      let success = this.$store.getters['learningPaths/getRoute'].contents.length >=1
      if(!success){
        this.errors.contents = 'contents_missing'
      }

      return new Promise((resolve, reject) => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    },
    async getData(){
      this.loading = true
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'token': this.$store.getters['user/getUserData'].sso,
      }
      await this.$http.post(getCreateLearningPathInitialData, data).then( response => {
        // console.log("getCreateLearningPathInitialData", response)
        if(response.data.status == 200){
          this.langs = response.data.langs
          this.lp_modes = response.data.lp_modes

          this.$store.commit('learningPaths/setContentFilterOptions', response.data.filterOptions)   
          this.$store.commit('learningPaths/setContentsHierarchy', response.data.contents_hierarchy)   
          this.$store.commit('learningPaths/setContents', response.data.contents)  

          if(this.$route.params.id){
            this.getLPData(this.$route.params.id)
          } else {
            this.langs.forEach(l => {
              this.$store.commit('learningPaths/setRouteTitle', ["", l.iso_code])
              this.$store.commit('learningPaths/setRouteDescription', ["", l.iso_code])
            });
            this.$store.commit('learningPaths/setRouteLanguages', [])   
            this.$store.commit('learningPaths/setRouteMode', this.lp_modes[0])   
            this.$store.commit('learningPaths/setRouteContents', [])   
            this.$store.commit('learningPaths/setRouteImg', 1)   
            this.$store.commit('learningPaths/setRouteCategories', [])   
          }

          this.loading = false
        } else {
          this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    async getLPData(id){
      this.loading = true
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'lp_id': id,
        'token': this.$store.getters['user/getUserData'].sso,
      }
      await this.$http.post(getLearningPathDetails, data).then( response => {
        // console.log("getLearningPathDetails", response)
        if(response.data.status == 200){
          
          let  my_route = {
            id: this.$route.name == 'learning-paths-edit' ? id : null,
            title: response.data.title,
            description: response.data.description,
            languages: response.data.langs,
            mode: response.data.mode,//{id: 1, name: "free"},
            img: response.data.img,
            contents: response.data.contents,
            categories: response.data.categories || []
          }

           //REVISAR
          this.$store.commit('learningPaths/setRoute', my_route)   
          // console.log("-------------------------", this.$store.getters['learningPaths/getRoute'])
          
          this.loading = false
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
          this.$router.push({name: 'learning-paths'})
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';


</style>