<template>
  <div>
    <b-row >
      <b-col>
        <app-collapse>

          <app-collapse-item :title="$t('learning_path.jerarquia')">
            <p>{{$t('learning_path.select_path_hierarchy')}}</p>

            <div v-if="$store.getters['learningPaths/getRoute'].categories">
              <b-badge pill 
                @click="selectedNode(item)"
                v-for="item in $store.getters['learningPaths/getRoute'].categories"
                :key="item.id"
                class="mr-25 mb-25 pointer"
                variant="primary">
                {{ item.name }}
                <feather-icon class="ml-25" icon="XIcon" />
              </b-badge>

            </div>

            <div class="p-1">
              <TreeNode 
                v-for="node in $store.getters['learningPaths/getSmileHierarchy']" 
                :key="node.id" 
                :node="node" 
                @selected-node="selectedNode"
              />
            </div>
            
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TreeNode from '../components/TreeNode.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { 
  BRow, BCol, BBadge
} from 'bootstrap-vue'
export default {
  components: {
    BRow, BCol, BBadge,

    AppCollapse,
    AppCollapseItem,
    TreeNode,
  },
  methods:{
    selectedNode(node){
      //mandar al store, gestionar jerarquias seleccionadas ?
      // console.log(node, "full node jerarquía seleccionada")

      let route_categories = [...this.$store.getters['learningPaths/getRoute'].categories]
      const index = route_categories.findIndex(object => {
        return object.id === node.id;
      });
      if(index < 0){
        route_categories.push(node)
      } else {
        route_categories.splice(index, 1)
      }
      this.$store.commit('learningPaths/setRouteCategories', route_categories)
    },
  }
}
</script>

<style>

</style>