<template>
  <div class="avatar-modal mb-2">
    <b-row style="text-align:right;">
      <b-col>
        <b-button
          variant="gradient-danger"
          class="btn-icon rounded-circle"
          @click="$emit('toggle-edit-avatar')"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
        <b-col cols='12'>
            <div class="avatar-selector-container">
                
                <div v-for="index in 110" :key="index"
                    class="avatar-item" 
                    @click="setAvatar(index)"
                    >
                    <img :src="require('@/assets/images/learning_paths/' + index + '.png?v4')"
                        :class="[currentAvatar == index ? 'current_avatar' : 'avatar']">
                </div>
                
            </div>
        </b-col>
    </b-row>

  </div>
</template>

<script>
import {
   BRow, BCol, BButton, 
} from 'bootstrap-vue'
export default {
    components:{
        BRow, BCol, BButton
    },
    data() {
        return {
            avatars: [],
            currentAvatar: this.avatar_id,
        }
    },
    props: ['avatar_id'],
    methods: {
      setAvatar(id){
        this.currentAvatar = id;
        this.$store.commit('learningPaths/setRouteImg', id);
      }
    }
}
</script>

<style lang="scss">

.avatar-modal{
            background-color: rgb(245, 245, 245);
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            h4{
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 20px;
            }
            .avatar-selector-container{
                max-height: 300px;
                overflow-y: scroll;
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: stretch;
                align-content: flex-start;
                gap: 10px;
            }
            .avatar-modes-container{
                max-height: 100px;
                overflow-y: scroll;
                margin: 25px 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                align-content:center;
                gap: 10px;
                width: -webkit-fill-available;

                .avatar-modes-item{
                    flex-grow: 1;
                    max-width: 94px;
                    max-height: 100px;
                    overflow: hidden;
                    img{
                        max-width: 70px;
                        max-height: 70px;
                    }
                    .current_avatar{
                        border-radius: 50%;
                        border: 5px solid #e27d02;
                        background-color: orange;
                        &:hover{
                            background-color: #ff8c00;
                            cursor: pointer;
                        }
                    }
                    .avatar{
                        /* background-color: #09b8dd;
                        border: 5px solid #0193b1; */
                        border-radius: 50%;
                        &:hover{
                            /* background-color: #02a3c4; */
                            cursor: pointer;
                        }
                    }
                }
            }
            .avatar-item{
                flex-grow: 1;
                max-width: 100px;
                max-height: 100px;
                overflow: hidden;
                img{
                    max-width: 100px;
                    max-height: 100px;
                }
                .current_avatar{
                    border-radius: 10px;
                    border: 4px solid #e27d02;
                    background-color: orange;
                    &:hover{
                        background-color: #ff8c00;
                        cursor: pointer;
                    }
                }
                .avatar{
                    background-color: #09b8dd;
                    border: 4px solid #0193b1;
                    border-radius: 10px;
                    &:hover{
                        background-color: #02a3c4;
                        cursor: pointer;
                    }
                }
            }
            .close-avatar-modal-button{
                cursor: pointer;
                position: relative;
                top:-30px;
                float: right;
                background-color: white;
                color: red;
                font-size: 25px;
                &:hover {
                    background-color: red;
                    color: white;
                }
                
                border-radius: 50px;
                height: 40px;
                width: 40px;
                z-index: 3;
                box-shadow: 3px 3px 15px #0000007d;
                i{
                    margin-top: 7px;  
                }
            }
        }
</style>